import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  token:any = '';
  empType:any = '';
  access = [
    "PRE PRODUCTION",
    "PURCHSE",
    "PRODUCTION",
    "POST PRODUCTION",
    "PAYMENT"
  ]
  constructor() { }
  getRole() {
    const currentuser = JSON.parse(sessionStorage.getItem('token'))
    this.token = currentuser.token
    currentuser.results.forEach(element => {
      this.empType = element['EmployeeType']
    });
    return this.empType;
  }

}
