import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class CompanyDetailsService {
  BaseUrl: any = environment.BaseUrl;
  constructor(private http: HttpClient) { }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  updatecompanyalottement(body){
    return this.http.put(this.BaseUrl + '/change/company/alotment', body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getEmployeehistory(body) {
    return this.http.post(this.BaseUrl + '/calling/history/details/datewise',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ParticularEmployeeCompanydata(body) {
    return this.http.post(this.BaseUrl + '/particular/employee/company/data',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  ParticularEmpCompanydata(body) {
    return this.http.post(this.BaseUrl + '/particular/employee/company/allotedtodata',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  // all filter apis
  getdistinctvalues(body){
    return this.http.post(this.BaseUrl + '/filter/company/info/distinctwise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getdistinctvaluesM(body){
    return this.http.post(this.BaseUrl + '/filter/company/info/distinctwise_manager',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }


  getDistinctbranch(body){
    return this.http.post(this.BaseUrl + '/get/distinct/branches',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  ///// GET BRANCH
  getParticularbranch(body) {
    return this.http.post(this.BaseUrl + '/branch/details/all/statuswise',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  //GET PERTICULAR BRANCH PERSON
  getParticularPerson(body){
    return this.http.post(this.BaseUrl + '/branch/person/all/branchwise',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  AllCompanyDetailsStatuswise() {
    return this.http.get(this.BaseUrl + '/company/details/all/list', httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  //Add New Company
  AddNewCompany(body){
    return this.http.post(this.BaseUrl + '/company/create',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ///// Add New Branch
  AddNewBranch(body) {
    return this.http.post(this.BaseUrl + '/branch/create/single',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  // ADD NEW BRANCH PERSON
  AddNewBranchPerson(body){
    return this.http.put(this.BaseUrl + '/branch/person/create',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  //Full DEtails OF ComapNAy
  FullCompanydetails(body){
    return this.http.post(this.BaseUrl + '/company/details/all/list/Companytype',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ///create calling remark
  getsectorcompany(body){
    return this.http.post(this.BaseUrl + '/get/companysector',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  //////////////////// COMPANY FORM REGISTRATION API
  ////// COMPANY NAME 
  getCompanyname(){
    return this.http.get(this.BaseUrl + '/get/CompanyNames',httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY SECTOR COMPANY NAME WISE
  getCompanySector(body){
    return this.http.post(this.BaseUrl + '/get/CompanySectors/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY BRAND COMPANY NAME WISE
  getCompanyBrand(body){
    return this.http.post(this.BaseUrl + '/get/CompanyBrand/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY SECTOR COMPANY NAME WISE
  getClientAlotted(body){
    return this.http.post(this.BaseUrl + '/get/ClientAlotted/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getallemployeeclient(){
    return this.http.get(this.BaseUrl + '/get/all/employees/name/alotted',httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  ////// COMPANY SECTOR COMPANY NAME WISE
  getFieldAlotted(body){
    return this.http.post(this.BaseUrl + '/get/FieldAlottedTo/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getallemployeefield(){
    return this.http.get(this.BaseUrl + '/get/all/employees/name/alotted/field',httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  ////// COMPANY Location COMPANY NAME WISE
  getCompanyLocation(body){
    return this.http.post(this.BaseUrl + '/get/CompanyLocation/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY LANDLINENO COMPANY NAME WISE
  getCompanyLandline(body){
    return this.http.post(this.BaseUrl + '/get/Company/Landlineno/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY Person COMPANY NAME WISE
  getCompanyPerson(body){
    return this.http.post(this.BaseUrl + '/get/Company/Contactperson/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY Person DESIGNATION COMPANY NAME WISE
  getCompanyPersonDesig(body){
    return this.http.post(this.BaseUrl + '/get/Company/Contactperson/Desig/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY Person MOBILE NO. COMPANY NAME WISE
  getCompanyPersonMobile(body){
    return this.http.post(this.BaseUrl + '/get/Company/Contactperson/Mobile/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY Person MOBILE NO. COMPANY NAME WISE
  getCompanyPersonLandline(body){
    return this.http.post(this.BaseUrl + '/get/Company/Contactperson/Landline/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY Person MOBILE NO. COMPANY NAME WISE
  getCompanyBusinesstype(body){
    return this.http.post(this.BaseUrl + '/get/Company/Bussiness/type/namewise',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY SECTOR COMPANY NAME WISE
  registernewCompany(body){
    return this.http.post(this.BaseUrl + '/register/new/company',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY SECTOR COMPANY NAME WISE
  getCompanyfullinfo(){
    return this.http.get(this.BaseUrl + '/get/Company/full/information',httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  upload(formData){
    return this.http.post(this.BaseUrl + '/import/Company/info',formData,{  
      reportProgress: true,  
      observe: 'events'  
    }).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY SECTOR COMPANY NAME WISE
  getparticularcompanydata(body){
    return this.http.post(this.BaseUrl + '/get/particular/company/data',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  ////// COMPANY SECTOR COMPANY NAME WISE
  updateparticularcompanydata(body){
    return this.http.put(this.BaseUrl + '/update/particular/company/details',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
    /////
  getClientType(){
    return this.http.get(this.BaseUrl + '/get/clientType/all',httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getcount(){
    return this.http.get(this.BaseUrl + '/get/company_Data_Count',httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  schedulecompanydata(body){
    return this.http.post(this.BaseUrl + '/particular/employee/company/scheduleNonSchedule',body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
