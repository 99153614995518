import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  BaseUrl: any = environment.BaseUrl;
  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
    ///// TODAY SCHEDULED CALL
    callingdashboarddetails(body) {
      return this.http.post(this.BaseUrl + '/calling/dashboards/details',body, httpOptions).pipe(
        catchError(this.handleError)
      );
    }

        ///// TODAY SCHEDULED CALL Count
        callingdashboarddetailsCounts(body) {
          return this.http.post(this.BaseUrl + '/calling/details/todaycounts',body, httpOptions).pipe(
            catchError(this.handleError)
          );
        }
    ///// Partricular Employee Company data
    notify(body) {
      return this.http.post(this.BaseUrl + '/notify',body, httpOptions).pipe(
        catchError(this.handleError)
      );
    }
}
