import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class EmpCompanyAndCallingService {
  BaseUrl: any = environment.BaseUrl;
  constructor(private http: HttpClient) { }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  commonPost(url, body) {
    return this.http.post(this.BaseUrl + url, body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  commonPostFormData(url, body) {
    return this.http.post(this.BaseUrl + url, body).pipe(
      catchError(this.handleError)
    );
  }
  commonGet(url) {
    return this.http.get(this.BaseUrl + url, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
