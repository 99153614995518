import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})

export class OrderDetailsService {
  BaseUrl: any = environment.BaseUrl;
  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  orderlist(body) {
    return this.http.post(this.BaseUrl + '/all/order/progresswise',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  allemployeedesigwise(body) {
    return this.http.post(this.BaseUrl + '/get/distinct/employees/designationwise',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  OrderAssignCreate(body) {
    return this.http.post(this.BaseUrl + '/create/order/assigning',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  commonget(url){
    return this.http.get(this.BaseUrl + url, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  commonPost(body, url){
    return this.http.post(this.BaseUrl + url, body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
